import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import UTILS from "src/app/shared/utils/utils";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  links: any[] = [
    { menu_title: "Impressum", menu_url: "/data-privacy" },
    { menu_title: "Datenschutz", menu_url: "/privacy-policy" },
    { menu_title: "Nutzungsbedingungen", menu_url: "/term-condition" },
  ];
  isConnex: boolean = false;
  isRegularUser: boolean = true;
  isToken: any;
  copyrightText: any = `<p>©&nbsp;Allianz ${new Date().getFullYear()}</p>\r\n`;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: SharedService
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart:
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.getFooterLinks();
          let footerApi = this.checkVendor();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    
    setTimeout(() => {
      const role = UTILS.rolePersonType();
      if (role) {
        if (role === "PROSPECTIVE" || role === "CANDIDATE") {
          this.isRegularUser = false;
        }
      }
      this.getFooterLinks();
    }, 1000);
  }

  getFooterLinks() {
    const slug = this.checkVendor();
    this.api.getFooterLinks(slug).subscribe(
      (response) => {
        if (response.hasOwnProperty("data") && response.status === "success") {
          const { data } = response;
          if (data && data.hasOwnProperty('menu_link') && !!data.menu_link) {
            this.links = data.menu_link.map((v) => {
              if (/http:\/\/|https:\/\//.test(v.menu_url)) {
                v.internal = false;
              } else {
                v.internal = true;
              }
              return v;
            });
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkVendor() {
    let pathname = window.location.pathname;
    if (
      pathname == "/ticketshop-checkout/fertig" ||
      pathname == "/auschecken/erfolg" ||
      /so_funktionierts\/faq/.test(window.location.pathname)
    ) {
      return "footer";
    }
    if (/ticketshop/.test(pathname)) {
      return "glockner-footer";
    } else if (
      /voucher|coupon|warenkorb|auschecken/i.test(pathname)
    ) {
      return "connex-footer";
    }
    return "footer";
  }
}

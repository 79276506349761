import { NgModule } from "@angular/core";
import { Routes, RouterModule ,ExtraOptions} from "@angular/router";
import { AuthGuardService as AuthGuard } from "./auth/auth-guard.service";

const routes: Routes = [
  {
    path: "einkaufsvorteile",
    redirectTo: "/einkaufsvorteile-redirect",
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "punkte_einloesen",
    loadChildren:
      () => import('../app/modules/redeem-points/redeem-points.module').then(m => m.RedeemPointsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "punkte_einloesen-redirect",
    loadChildren:
      () => import('../app/modules/redeemPoints-beta/redeemPoints-beta.module').then(m => m.RedeemPointsBetaModule),
    canActivate: [AuthGuard],
  },
  {
    path: "einkaufsvorteile-redirect",
    loadChildren:
      () => import('../app/modules/shoppingbenefits-beta/shoppingBenefits-beta.module').then(m => m.ShoppingBenifitsBetaModule),
    canActivate: [AuthGuard],
  },
  {
    path: "ticketshop-list",
    loadChildren:
      () => import('../app/modules/ticketshop/ticketshop-list/ticketshop-list.module').then(m => m.TicketShopListModule),
    canActivate: [AuthGuard],
  },
  {
    path: "ticketshop-redirect",
    loadChildren:
       () => import('../app/modules/ticketshop-beta/ticketshop-beta.module').then(m => m.TicketshopBetaModule),
      
    canActivate: [AuthGuard],
  },
  {
    path: "wartungsarbeiten",
    loadChildren:
      () => import('../app/modules/maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [AuthGuard],
  },
  {
    path: "so_funktionierts",
    loadChildren:
      () => import('../app/modules/how-it-works/how-it-works.module').then(m => m.HowItWorksModule),
    canActivate: [AuthGuard],
  },
  {
    path: "punktekonto",
    loadChildren:
      () => import('../app/modules/point-account/point-account.module').then(m => m.PointAccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: "auschecken",
    loadChildren: () => import('../app/modules/checkout/checkout.module').then(m => m.CheckoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: "warenkorb",
    loadChildren: () => import('../app/modules/cart/cart.module').then(m => m.CartModule),
    canActivate: [AuthGuard],
  },
  {
    path: "coupon",
    loadChildren: () => import('../app/modules/coupon/coupon.module').then(m => m.CouponModule),
    canActivate: [AuthGuard],
  },
  {
    path: "voucher",
    loadChildren: () => import('../app/modules/voucher/voucher.module').then(m => m.VoucherModule),
    canActivate: [AuthGuard],
  },
  {
    path: "gewinnspiel",
    loadChildren: () => import('../app/modules/lottery/lottery.module').then(m => m.LotteryModule),
    canActivate: [AuthGuard],
  },
  {
    path: "ticketshop",
    loadChildren:
      () => import('../app/modules/ticketshop/direct-purchase/direct-purchase.module').then(m => m.DirectPurchaseModule),
    canActivate: [AuthGuard],
  },
  {
    path: "newsletter-abonnieren",
    loadChildren:
      () => import('../app/modules/subscribe-newsletter/subscribe-newsletter.module').then(m => m.SubscribeNewsletterModule),
    canActivate: [AuthGuard],
  },
  {
    path: "ticketshop-warenkorb",
    loadChildren:
      () => import('../app/modules/ticketshop/ticketshop-cart/ticketshop-cart.module').then(m => m.TicketShopCartModule),
    canActivate: [AuthGuard],
  },
  {
    path: "ticketshop-checkout",
    loadChildren:
      () => import('../app/modules/ticketshop/ticketshop-checkout/ticketshop-checkout.module').then(m => m.TicketShopCheckoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: "uebersicht",
    loadChildren: () => import('../app/modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: "newsletter-abbestellen",
    loadChildren:
      () => import('../app/modules/unsubscribe-newsletter/unsubscribe-newsletter.module').then(m => m.UnSubscribeNewsletterModule),
  },
  {
    path: "datenschutz",
    loadChildren:
      () => import('../app/modules/cookie-redirect-url/cookie-redirect-url.module').then(m => m.CookieRedirectUrlModule),
  },
  {
    path: "kommt_bald",
    loadChildren: () => import('../app/modules/kommt-bald/kommt-bald.module').then(m => m.KommtBaldModule),
  },
  {
    path: "404",
    loadChildren: () => import('../app/modules/not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: "blocked",
    loadChildren: () => import('../app/modules/blocked/blocked.module').then(m => m.BlockedModule),
  },
  {
    path: "login",
    loadChildren: () => import('../app/modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: "internet-explorer",
    loadChildren:
      () => import('../app/modules/internet-explorer/internet-explorer.module').then(m => m.InternetExplorerModule),
  },
  {
    path: ":slug",
    loadChildren: () => import('../app/modules/static/static.module').then(m => m.StaticModule),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  { path: "",
    redirectTo: "uebersicht",
    pathMatch: "full" },
  {
    path: "**",
    loadChildren: () => import('../app/modules/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];

// Explicitly specify the relativeLinkResolution option in ExtraOptions
// const routerOptions: ExtraOptions = {
//   relativeLinkResolution: 'legacy' 
// };

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { MOBILE_BREAK_POINT } from "../../utils/variables";
import UTILS from "src/app/shared/utils/utils";
import { SharedService } from "../../services/shared.service";
import { AuthService } from "src/app/auth/auth.service";
import { STATIC_URL, TOKEN } from "src/environments/environment";
import { USER_FULL_DETAILS } from "../../constants/constants";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "(window:resize)": "onResize($event)",
    "(window:scroll)": "onScroll($event)",
  },
})
export class HeaderComponent implements OnInit {
  @ViewChild("mobileSearch") mobileSearch: ElementRef;
  @Input() rewardUOM = "Punkte";

  isMobile: boolean;
  width: number;
  isMenuOpen = false;
  showInfoMenu = false;
  changeValue = "";
  wishListCount: any = 0;
  logoSrc: string = "/assets/images/logo.svg";
  logoAlt: string = "Allianz Global Brand Logo";
  isConnex: boolean = false;
  fullName: string = "";
  isClass: string = "regular-header";
  noHeader: boolean = false;
  glocknerCls: string = "";
  isMobileLogo: boolean = false;
  logoRedirection: string = "";
  userRewardPoints: any;

  constructor(
    private api: SharedService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart:
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          let pathname = window.location.pathname;
          this.isMobileLogo = false;
          if (
            /ticketshop/.test(pathname) &&
            !/so_funktionierts\/faq/.test(pathname)
          ) {
            this.logoSrc = "/assets/images/glockner.svg";
            this.logoAlt = "Glockner Global Brand Logo";
            this.glocknerCls = "glockner-logo";
            this.logoRedirection = "/ticketshop-list";
          } else if (
            /voucher|coupon|warenkorb|auschecken/i.test(
              pathname
            ) &&
            !/so_funktionierts\/faq/.test(pathname)
          ) {
            this.logoSrc = "/assets/images/connex-logo.png";
            this.logoAlt = "Connex Global Brand Logo";
            this.glocknerCls = "";
          } else if (
            UTILS.detectMobile() &&
            /wartungsarbeiten|404/i.test(pathname)
          ) {
            this.logoSrc = "/assets/images/Allianz-small.png";
            this.logoAlt = "Allianz Global Brand Logo";
            this.isConnex = false;
            this.glocknerCls = "";
            this.isMobileLogo = true;
            this.logoRedirection = "/uebersicht";
          } else {
            this.logoSrc = "/assets/images/logo-allianz.svg";
            this.logoAlt = "Allianz Global Brand Logo";
            this.isConnex = false;
            this.glocknerCls = "";
            this.logoRedirection = "/uebersicht";
          }
          if (/404|wartungsarbeiten|newsletter-abbestellen/i.test(pathname)) {
            this.noHeader = true;
            this.glocknerCls = "";
          }
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  @ViewChild("header") header: ElementRef;

  isSticky = false;
  offset: { height: number; top: number };
  CXLHeader: boolean = false;
  isToken: any;

  ngOnInit() {
    this.handleResize(window.innerWidth);
    setTimeout(() => {
      this.checkMMuser();
      this.getPointScoreDetail();
    }, 1000);
  }

  ngAfterViewInit() {
    this.offset = this.header.nativeElement.getBoundingClientRect();
  }

  onResize(event) {
    const width = event.target.innerWidth;
    UTILS.debounce(this.handleResize).call(this, width);
  }

  onScroll(event) {
    const { height, top } = this.offset;
    const number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    UTILS.debounce(this.stickOnScroll).call(this, height, number);
  }

  stickOnScroll(offset: number, number: number) {
    this.isSticky = UTILS.onScrollOffset(offset, number);
  }

  handleResize(width: number) {
    if (width < MOBILE_BREAK_POINT) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
  toggleMenu(type: string) {
    if (type == "info") {
      this.showInfoMenu = true;
      this.isMenuOpen = !this.isMenuOpen;
    }
    if (type == "menu") {
      this.showInfoMenu = false;
      this.isMenuOpen = true;
    }
  }

  isAuthenticated = () => {
    if (typeof window == "undefined") {
      return false;
    }
    if (sessionStorage.getItem("overlay")) {
      return true;
    } else {
      return false;
    }
  };

  searchResult(event: any) {
    this.changeValue = event.target.value;
  }

  searchClickHandler(event: any) {
    event.preventDefault();
    window.location.href = `${window.location.origin}/search?search_api_fulltext=${this.changeValue}`;
  }

  private getPointScoreDetail() {
    this.userRewardPoints = UTILS.getUserMemberPoints();
    const user = JSON.parse(sessionStorage.getItem(USER_FULL_DETAILS));

    if (this.userRewardPoints && user) {
      let { first_name, NobilityDescription, last_name, properties } = user;
      this.fullName = `${first_name} ${NobilityDescription !== null ? NobilityDescription : ""} ${last_name}`;
      if (properties && properties.hasOwnProperty("PersonType")) {
        const { PersonType } = properties;
        if (
          (PersonType && PersonType === "PROSPECTIVE") ||
          PersonType === "CANDIDATE"
        ) {
          this.isClass = "prospect-header";
        }
      }
    }
  }

  signoutHandler(event) {
    event.preventDefault();
    this.auth.logOutUser();
  }

  checkMMuser() {
    this.api.getMMUserDetails().subscribe(
      (response) => {
        const { mm_user, status } = response;
        if (status == "success") {
          sessionStorage.setItem("mmuser", String(mm_user));
        } else {
          sessionStorage.removeItem("mmuser");
        }
      },
      (error) => {
        sessionStorage.removeItem("mmuser");
      }
    );
  }

  redirectForAccountSummary() {
    return STATIC_URL.account_summary();
  }

  homePageUrl() {
    return STATIC_URL.homepage_url();
  }

  isIEBrowser() {
    return UTILS.isIEBrowser();
  }
}

 